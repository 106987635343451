
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {PaymentType} from "@/enum/PaymentType.enum";

//@ts-ignore
import {loadScript, Payment} from "@paypal/paypal-js";
import {googlePayOptions} from '@/misc/GooglePayOptions';

//@ts-ignore
import braintree from 'braintree-web';
import PaymentRepository from "@/api/repositories/PaymentRepository";
import UtilRepository from "@/api/repositories/UtilRepository";

interface AvailablePaymentMethod {
  label: string,
  image: string,
  type: PaymentType
}

@Component({
  components: {
    GooglePayButton: () => import(
      /* webpackChunkName: "GooglePayButton" */
      '@/components/Payment/GooglePayButton.component.vue'
    ),
  }
})
export default class PossiblePaymentListComponent extends Vue {
  @Prop({required: true})
  private onAddTypeClicked!: (type: PaymentType) => void;

  @Prop({required: true})
  private onPaypalApprovedCallback!: (nonce: any) => void;

  @Prop({required: true})
  private companyId!: string;

  @Prop({required: true})
  private onGooglePayTokenCreated!: (value: any) => Promise<void>

  private get googleOptions() {
    return googlePayOptions;
  }

  private get scriptSrc() {
    const id = process.env.VUE_APP_PAYPAL_CLIENT_ID;
    return `https://www.paypal.com/sdk/js?client-id=${id}&vault=true&disable-funding=card`;
  }

  /**
   * is called when the user tries to connect paypal, tries to get the paypal connectUrl and opens it
   * @private
   */
  private async registerPaypal(): Promise<void> {
    try {
      const response = await PaymentRepository.getPaypalAuthentication();
      const url = response.data['connectUrl'];
      window.open(url);
    } catch(e) {
      console.log(e);
    }
  }

  /**
   * TODO: removed for now
   * token that is loaded by the api, used to authorize at braintree
   * @private
   */
  // private token: string | null = null;

  /**
   * flag that indicates if the screen is loading
   * @private
   */
  private isLoadingPossibleMethods = true;

  /**
   * flag that indicates if loading possible payment methods failed
   * @private
   */
  private hasErrorLoadingPossibleMethods = false;

  /**
   * TODO: removed for now
   * when using components, vue likes to have the "is" parameter as v-bind,
   * therefore just a getter that returns the script string so vue is happy
   * @private
   */
  // private get isScript() {
  //   return 'script';
  // }

  /**
   * all available payment methods
   * @private
   */
  private availablePaymentMethod: AvailablePaymentMethod[] = [
    {label: this.$t('GENERAL.PAYMENT_METHODS.PAYPAL').toString(), image: '/payment/paypal_icon.png', type: PaymentType.PAYPAL},
    // TODO: removing payment method that are not PAYPAL for now, they will be reintroduced later
    // {label: this.$t('GENERAL.PAYMENT_METHODS.CARD').toString(), image: '/payment/credit_card.png', type: PaymentType.CREDIT_CARD},
    // {label: this.$t('GENERAL.PAYMENT_METHODS.GOOGLE_PAY').toString(), image: '/payment/google_pay.png', type: PaymentType.GOOGLE_PAY}
  ]

  // TODO: removed for now
  // private async setUpPaypal() {
  //   const response = await PaymentRepository.setupIntent('PAYPAL', this.companyId);
  //   this.token = response.data['braintreeToken'];
  //
  //   // TODO: use real client id here
  //   const paypal = await loadScript(({ "client-id": 'sb', vault: true, locale: 'de_DE', intent: 'tokenize', currency: 'EUR',
  //     "disable-funding": 'card'}));
  //
  //   this.isLoadingPossibleMethods = false;
  //   this.hasErrorLoadingPossibleMethods = false;
  //
  //   const clientInstance = await braintree.client.create({authorization: this.token});
  //   const paypalCheckoutInstance = await braintree.paypalCheckout.create({client: clientInstance});
  //
  //   try {
  //     await paypal!.Buttons!({
  //       onApprove: (d: any, a: any) => this.onPaypalApproved(d,a, paypalCheckoutInstance),
  //       onError: this.onPaypalError,
  //       createBillingAgreement: function () {
  //         return paypalCheckoutInstance.createPayment({
  //           flow: 'vault'
  //         });
  //       },
  //     })?.render('#paypal-button');
  //   } catch(_) {
  //     // using here an explicit try and catch for the setup because it can happen that the render process fails, this
  //     // happens when the user is leaving the view before the rendering is fully completed (since the buttons are
  //     // rendered asynchronous), therefore we are just catching this case to avoid showing error messages / toasts but
  //     // there is nothing to do actually inside the catch
  //   }
  // }

  public async created() {
    try {
      await UtilRepository.testPost();
    } catch(_) {
      // by default this method will fail on purpose since user has no
      // csrf-token, however by executing this call the user will receive
      // an token afterwards, so this needs to be done in order to make
      // the rest work
    }
    await this.getPossibleMethods();
  }

  /**
   * tries to get all possible payment methods
   * @private
   */
  private async getPossibleMethods() {
    try {
      this.isLoadingPossibleMethods = true;

      // TODO: removed for now
      // await this.setUpPaypal();
    } catch (e) {
      console.log(e);
      this.hasErrorLoadingPossibleMethods = true;
      this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.PAYMENT_LOAD_POSSIBLE_METHODS_ERROR");
    } finally {
      this.isLoadingPossibleMethods = false;
    }
  }

  /**
   * TODO: removed for now
   * is called when paypal was connected
   * @param data
   * @param actions
   * @param paypalCheckoutInstance
   * @private
   */
  // private async onPaypalApproved(data: any, actions: any, paypalCheckoutInstance: any) {
  //   try {
  //     const payload = await paypalCheckoutInstance.tokenizePayment(data);
  //     await this.onPaypalApprovedCallback(payload.nonce);
  //     this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_ADD_METHOD.PAYPAL.SUCCESS');
  //   } catch (_) {
  //     this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_ADD_METHOD.PAYPAL.ERROR');
  //   }
  // }

  /**
   * TODO: removed for now
   * is called when paypal threw an error during setup
   * @param error
   * @private
   */
  // private onPaypalError(error: any) {
  //   // using here a call-up for checking if the error has to do with the div '#paypal-button', because it can happen
  //   // that the render process fails, this happens when the user is leaving the view before the rendering is fully
  //   // completed (since the buttons are rendered asynchronous),
  //   // therefore we don't want to show the error toast (since there is no problem)
  //   if(error.toString().includes('#paypal-button')) return;
  //
  //   this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PAYMENT_ADD_METHOD.PAYPAL.ERROR');
  // }

  /**
   * checks if the payment method is of type credit card
   * @param type
   * @private
   */
  private isPaypal(type: PaymentType) {
    return type === PaymentType.PAYPAL;
  }

  private isGooglePay(type: PaymentType) {
    return type === PaymentType.GOOGLE_PAY;
  }

  private isCardMethod(type: PaymentType) {
    if(this.isGooglePay(type)) return false;
    return type === PaymentType.CREDIT_CARD;
  }
}
