/**
 * settings for google pay
 */
export const googlePayOptions = {
    environment: process.env.VUE_APP_GOOGLE_PAY_ENVIRONMENT!,
    buttonColor: 'white',
    baseRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
    },
    allowedCardNetworks: [
        'AMEX',
        'MASTERCARD',
        'VISA'
    ],
    allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    merchantInfo: {
        merchantName: process.env.VUE_APP_GOOGLE_PAY_MERCHANT_NAME!,
        merchantId: process.env.VUE_APP_GOOGLE_PAY_MERCHANT_ID!
    },
    tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            "gateway": "stripe",
            "stripe:version": process.env.VUE_APP_GOOGLE_PAY_STRIPE_VERSION!,
            "stripe:publishableKey": process.env.VUE_APP_STRIPE_PK!
        }
    },
    transactionInfo: {
        totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
        currencyCode: 'EUR',
        countryCode: 'DE'
    },
    baseCardPaymentMethod: {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: [
                'AMEX',
                'MASTERCARD',
                'VISA'
            ]
        }
    },
    cardPaymentMethod: null,
    paymentDataRequest: {
        baseRequest: {
            apiVersion: 2,
            apiVersionMinor: 0
        },
        allowedPaymentMethods: null,
        transactionInfo: {
            totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
            currencyCode: 'EUR',
            countryCode: 'DE'
        },
        merchantInfo: {
            merchantName: process.env.VUE_APP_GOOGLE_PAY_MERCHANT_NAME!,
            merchantId: process.env.VUE_APP_GOOGLE_PAY_MERCHANT_ID!
        }
    }
};